import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function HACLogo() {
  return (<StaticImage
      src="../images/HAC_newlogo.png"
      alt="Housing Action Coalition Logo"
      placeholder="blurred"
      width={170}
      className=""
      // transformOptions={{fit: "cover", cropFocus: "center"}}
    />
  )
}
