import * as React from "react"
import type { HeadFC, PageProps } from "gatsby"
import { Hero } from "../sections/homepage/hero";
import { HolidaySection } from "../sections/homepage/cities-going-holiday";
import { HowWeCelebrate } from "../sections/homepage/how-we-celebrate";
import { HowToParticipate } from "../sections/homepage/how-to-participate";
import { FAQs } from "../sections/homepage/faqs";
import { WhoWeAre } from "../sections/homepage/who-we-are";
import { Header } from "../sections/homepage/header";
import { Toaster } from "react-hot-toast";

// const actionNetworkApiUrl = "https://actionnetwork.org/api/v2/forms/f3b77049-af4d-4cb0-89f1-5d03459043b5/"
const IndexPage: React.FC<PageProps> = () => {
  return (
    <main className="pb-16">
      <Toaster></Toaster>
      <Header></Header>
      <Hero></Hero>
      <div id='can-form-area-74e3c1f8c1af401adfeadcaf631f4997572a77e4' style={{width: "100%"}}></div>
      <HolidaySection></HolidaySection>
      {/*<HowWeCelebrate></HowWeCelebrate>*/}
      {/*<HowToParticipate></HowToParticipate>*/}
      <FAQs></FAQs>
      <WhoWeAre></WhoWeAre>
    </main>)
}
export default IndexPage

const descr = "There's a Zoning Holiday in California (you may have heard it " +
  "called the Builder's Remedy). It's an opportunity when there will be a faster, easier way to get the " +
  "permits you need to start building more homes on your property. The opportunity is already " +
  "available in dozens of Southern California cities, and will soon be available in many Bay Area " +
  "cities! Learn how to take advantage of this limited opportunity to bypass single-family only zoning " +
  "and build more homes."

// import HeroImage from '../images/hero-image-3-buildings.png';

const title = "California cities are going on a zoning holiday!"
const url = "https://zoningholiday.com"
export const Head: HeadFC = () => {
  return <>
    <title>{title}</title>
    <meta name="title" property="og:title" content={title} />
    <meta name="description" property="og:description" content={descr}/>
    <meta name="image" property="og:image" content={url+"/og-hero-image-3-buildings-1200.png"}/>
    <meta name="robots" content="index, follow"/>
    <link rel="canonical" href={url}/>

    <meta name="og:title" content={title}/>
    <meta name="og:description" content={descr}/>
    <meta name="og:image" content={url+"/og-hero-image-3-buildings-1200.png"}/>
    <meta name="og:url" content={url}/>
    <meta property="og:type" content="website"/>

    <meta name="twitter:card" content="summary_large_image"/>
    <meta name="twitter:title" content={title}/>
    <meta name="twitter:url" content={url}/>
    <meta name="twitter:description" content={descr}/>
    <meta name="twitter:image" content={url+"/og-hero-image-3-buildings-1200.png"}/>
    <meta name="twitter:creator" content="@Home3Co"/>
    <meta name="twitter:site" content="@Home3Co"/>
    <link id="favicon-icon" rel="icon"
          href="data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'><text y='0.9em' font-size='90'>👤</text></svg>"/>

  </>
}
