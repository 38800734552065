import React, { ReactNode } from 'react';
import toast from "react-hot-toast";

function FaqItem({ question, children }: { question: string, children: ReactNode }) {
  const [open, setOpen] = React.useState<boolean>(false);
  const openText = open ? "collapse-open" : "collapse-close"
  return (
    <div
      tabIndex={0}
      className={"collapse collapse-plus border-b border-base-300 bg-base-100 " + openText}
    >
      <div className="collapse-title cursor-pointer text-2xl font-medium"
           onClick={() => {
             setOpen(!open);
           }}
      >
        {question}
      </div>
      <div className="collapse-content">
        {children}
      </div>
    </div>
  )
}

export function FAQs() {

  return (
    <section className="pt-8 lg:pt-16 pb-12">
      <div className="container flex flex-col lg:flex-row justify-between items-stretch gap-16">
        <div className="lg:w-1/3 flex-grow">
          <div className="flex flex-col justify-between h-full gap-8">
            <div><p className="">Drop us a line at{' '}
              <span className="text-pink_accent">
              <a href="mailto:hello@zoningholiday.com">hello@zoningholiday.com</a>
            </span> if you have additional questions.</p>
            </div>
            <div className="text-left">
              <a href="mailto:hello@zoningholiday.com">
                <button className="bflex-grow w-full bg-light_black hover:bg-neutralgray-700
            text-neutralgray-200 font-bold py-2 px-4 h-20 rounded-full max-w-sm">Contact us
                </button>
              </a>
            </div>
          </div>
        </div>
        <div className="lg:w-2/3">
          <h1>Frequently asked questions</h1>
          <FaqItem question="What is a Zoning Holiday?">
            The Zoning Holiday refers to window of time when normal zoning rules will not apply in a city,
            and projects of any size can be proposed via a process colloquially called
            <strong>Builders Remedy</strong>. You can learn more from
              <a className='text-blue-600 underline dark:text-blue-500 hover:no-underline'
                 href="https://www.yimbylaw.org/buildersremedy">
                YIMBY Law's page about Builders Remedy</a>.
          </FaqItem>
          <FaqItem question="What is the Passive House Standard?">
            <p>The passive house standard is a voluntary standard which
              emphasizes energy-efficient design and construction techniques to reduce environmental
              impact and improve energy performance. The standard has been shown to result in comfortable, healthy,
              and energy-efficient buildings.
              <a className='text-blue-600 underline dark:text-blue-500 hover:no-underline'
                 href="https://naphnetwork.org/what-is-passive-house/">
                Learn more about Passive House Standard</a>.
            </p>
          </FaqItem>

          <FaqItem question={"Where can I learn more about the CEQA exemption for 5 units ?"}>
            <p>
              CEQA categorical exemptions allow local agencies to bypass the CEQA
              process for qualifying projects. Section 15303(b) lays out requirements for the Class 3 exemption which
              includes small multifamily developments of up to 6 dwelling units in urbanized areas like San Francisco.
              You can learn more about the CEQA class 3 exemption from{' '}
              <a
                href="https://blog.home3.co/i/79219936/bucket-use-the-small-infill-exemption">
                the "small infill" section of this Home3 blog post</a>.
            </p>

          </FaqItem>
          <FaqItem question="Where can I learn whether a particular city is in compliance?">
            <p>The Campaign for Fair Housing Elements maintains a {' '}
              <a
                href='https://www.fairhousingelements.org/he-status'>Housing Element Compliance</a>{' '}
              page, with visual and tabular data about the compliance status of each California jurisdiction.
            </p>
          </FaqItem>
        </div>
      </div>
    </section>

  )
}
