import React from 'react';
import { PHNLogo } from "../../components/PHNLogo";
import { Home3Logo } from "../../components/Home3Logo";
import { YimbyLawLogo } from "../../components/YimbyLawLogo";
import { LCILogo } from "../../components/LCILogo";
import { HACLogo } from "../../components/HACLogo";
import { PHCALogo } from "../../components/PHCALogo";


export function WhoWeAre() {

  return (
    <section className="container pt-8 lg:pt-16">
      <h1 className="mb-4">Who we are</h1>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 items-stretch justify-between">
        <div className="flex-1 mt-5">
          <div className="h-[64px] mb-6 flex items-center">
            <a href="https://naphnetwork.org" target="_blank"><PHNLogo/></a>
          </div>
          <p><span className="font-bold">The Passive House Network (PHN) is a 501(c)(3) training and membership non-profit that
						promotes
						international Passive House standards across the United States.</span></p><p>Passive House Network offer
          specialized courses,
          conferences and events to engage and educate building professionals, policymakers and the general
          public.
        </p><p><a className="text-inherit" href="https://naphnetwork.org/about-us-overview/" target="_blank">
          Learn more</a> about <span className="font-bold">Passive House Network</span>.</p>
        </div>

        <div className="flex-1 mt-5">
          <div className="h-[64px] mb-6 flex items-center">
            <a href="https://home3.co" target="_blank"><Home3Logo/></a>
          </div>
          <p><span className="font-bold">Home3 is a real estate investment platform connecting investors with developers solving the California
						housing crisis. </span></p><p>Home3 finds and vets the best projects by real estate developers using new
          California laws to
          unblock the development of more walkable, sustainable housing in coveted neighborhoods. We invest in the
          projects and offer them for co-investment to our investor network. Our curated, vetted projects from
          innovative developers are good for your portfolio, good for your community, and good for California.
        </p><p><a className="text-inherit" href="https://home3.co" target="_blank">
          Learn more</a> about <span className="font-bold">Home3</span>.</p>
        </div>

        <div className="flex-1 mt-5">
          <div className="h-[64px] mb-6 flex items-center">
            <a href="https://yimbylaw.org" target="_blank"><YimbyLawLogo/></a></div>
          <p><span className="font-bold">YIMBY Law’s mission is to end the housing shortage and achieve affordable, sustainable, and equitable
						housing for all. </span>
          </p><p>YIMBY Law lead grassroots oversight and takes legal action so that housing laws are followed,
          more homes are built, and housing becomes affordable and equitable. We also work with state and
          local agencies, advocates, and developers to improve housing laws and their implementation. YIMBY Law is the
          legal arm of the pro-housing movement and is housed in YIMBY Action’s 501(c)(3) affiliate, Yes In My Back
          Yard.
        </p><p><a className="text-inherit" href="https://yimbylaw.org" target="_blank">
          Learn more</a> about <span className="font-bold">YIMBY Law</span>.</p>
        </div>


        <div className="flex-1 mt-5">
          <div className="h-[64px] mb-6 flex items-center">
            <a href="https://www.livablecommunitiesinitiative.com/" target="_blank"><LCILogo/></a></div>
          <p><span className="font-bold">The Livable Communities Initiative mission is to address our housing
            and human dignity crisis by building housing for all Angelenos in safe, sustainable communities.
          </span>
          </p>
          <p>
            The Livable Communities Initiative is a plan to create vibrant, walkable 15-minute communities near job
            centers and transit, where most residents' needs can be met without having to drive. The LCI envisions
            parking-free, mid-rise mixed-use infill housing on small commercial lots under 8,000 square feet. The
            plan
            includes streamlining and lowering construction costs to democratize development.

          </p><p><a className="text-inherit" href="https://www.livablecommunitiesinitiative.com/" target="_blank">
          Learn more</a> about the <span className="font-bold">Livable Communities Initiative</span>.</p>
        </div>

        <div className="flex-1 mt-5">
          <div className="h-[64px] mb-6 flex items-center">
            <a href="https://housingactioncoalition.org/" target="_blank"><HACLogo/></a></div>
          <p><span className="font-bold">The Housing Action Coalition (HAC) is a member-supported nonprofit that advocates for building more
          homes at all levels of affordability to help alleviate the Bay Area and California’s housing shortage,
          displacement, and affordability crisis.
        . </span>
          </p><p>
          We specifically support new multi-family housing projects that are well-designed, environmentally-sustainable,
          and located near jobs and transit. And we work hard in collaboration with pro-housing lawmakers and partners
          to enact new laws to make it faster, easier, and less costly to build homes for residents of all income
          levels.

        </p><p><a className="text-inherit" href="https://housingactioncoalition.org/" target="_blank">
          Learn more</a> about the <span className="font-bold">Housing Action Coalition</span>.</p>
        </div>

        <div className="flex-1 mt-5">
          <div className="h-[64px] mb-6 flex items-center">
            <a href="https://housingactioncoalition.org/" target="_blank"><PHCALogo/></a></div>
          <p><span className="font-bold">Passive House California (PHCA) is a 501c3 non-profit with the
            mission to promote awareness, understanding and application of the International Passive
            House standard in California through education, public outreach, and advocacy.
         </span>
          </p><p>
          We are working in collaboration with the global Passive House community, local governments
          and like-minded, mission-driven organizations to transform our built environment for a
          healthier, more resilient present and a sustainable future.

        </p><p><a className="text-inherit" href="https://passivehousecal.org" target="_blank">
          Learn more</a> about <span className="font-bold">Passive House California</span>.</p>
        </div>


      </div>
    </section>

  )
}
