import React from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";

type Inputs = {
  emailforupdates: string,
  // exampleRequired: string,
};

export default function EmailForm() {
  const { register, handleSubmit, watch, setError, formState: { errors } } = useForm<Inputs>();
  const onSubmit: SubmitHandler<Inputs> = data => {
    // custom domain is useful for development on local host.
    const url = process.env.CLOUDFLARE_FUNCTIONS_DOMAIN
      ? process.env.CLOUDFLARE_FUNCTIONS_DOMAIN +'/api/get-updates'
      : 'https://zoningholiday.com/api/get-updates';
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ email: data.emailforupdates })
    };
    fetch(url, requestOptions)
      .then(response => {
        if (response.ok) {
          console.log('Submitted successfully')
          toast.success("Thanks for signing up! Check your email for a confirmation",
            {
              style: { width: '800px', maxWidth: '100%' },
              duration: 5000,
            })
        } else {
          response.json().then(json => {
            setError('emailforupdates', { type: 'manual', message: json })
          })
        }
        console.log(response)
      })
      .catch(error => {
        console.log('Form submit error', error)
        setError('emailforupdates', { type: 'manual', message: 'Error submitting form!' })
      })

  }

  // console.log(watch("emailforupdates")) // watch input value by passing the name of it

  return (<>
      <form className="flex bg-neutralgray-200 border-4 rounded-full border-solid h-20 items-center pr-2 pl-5 max-w-md"
            onSubmit={handleSubmit(onSubmit)}>
        {/* register your input into the hook by invoking the "register" function */}
        <input placeholder="Email..."
               className="w-full border-none outline-none bg-transparent flex-grow text-neutralgray-800"
               {...register("emailforupdates", { required: true })} />

        {/* errors will return when field validation fails  */}
        {/*{errors.emailforupdates && <span>This field is required</span>}*/}

        <input className="btn bg-pink_accent border-none rounded-full h-14 min-h-0 px-[20px] text-[14px]"
               type="submit"/>
      </form>
      <p className="!text-pink_accent font-bold px-[20px]">{errors.emailforupdates?.message}</p>
    </>
  );
}
