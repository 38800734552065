import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function LCILogo() {
  return (<StaticImage
      src="../images/LCI-logo-4-grayscale.png"
      alt="Living Coalition Initiative Logo"
      placeholder="blurred"
      width={120}
      className=""
      transformOptions={{fit: "cover", cropFocus: "center"}}
    />
  )
}
