import React from 'react';
import { StaticImage } from "gatsby-plugin-image";
import { IconArrowRight } from "@tabler/icons-react";
import EmailForm from "../../components/EmailForm";


export function HolidaySection() {

  return (
    <section className="bg-light_black dark pt-8 md:pt-12 lg:pt-16 pb-8">
      <div className="flex container items-center flex-col lg:flex-row gap-8">

        <div className="lg:w-2/3 flex-auto">
          <h1>Many California cities are going on a zoning holiday!</h1>
          <p>Many California cities have not met State-mandated requirements to allow more housing to be built, so the
            State has
            stepped in to allow for <span className="text-bold text-pink_accent">Builders Remedy</span> solutions.
            This means typical zoning regulations like density,
            height and FAR limits are
            sent on holiday! While local zoning rules are on vacation, owners and infill developers can submit
            housing solutions constrained only by their imagination, building codes, and CEQA strategy.
          </p>
          <p>We are celebrating these Zoning Holidays by partnering with architects who have designed 5-unit buildings
            for single-family sized lots, using modern Passive House design standards and the CEQA Class 3 exemption,
            to push California towards a more affordable, sustainable housing future.

          </p>
          <p>
            We showcased submissions at the <span className="text-pink_accent font-bold">Zoning Holiday Party on February 1</span>
            {' '}in San Francisco. But while you missed a great party, the designs are here online.
          </p>
          <a
            className={"flex-grow flex items-center justify-center text-center no-underline" +
              " bg-neutralgray-200 hover:bg-neutralgray-300 text-neutralgray-800 font-bold py-2 px-16 h-20 mt-12" +
              " rounded-full max-w-md"}
            href={"/designs"}>
            Check out the designs! <IconArrowRight className="ml-3 sm:hidden md:inline"/></a>


        </div>
        <div className="flex-auto lg:w-1/3 max-w-xl overflow-hidden text-center 2xl:mr-[50px]">
          <StaticImage
            src="../../images/overhead-neighborhood_edges30.png"
            alt="Hero image"
            placeholder="blurred"
            className="md:min-w-[640px] mx-[-30px]"
          />
          <p className="text-xs font-light font-sans text-left">Image credit: Lanefab Design/Build</p>
        </div>

      </div>

      <div
        className='container grid sm:grid-cols-1 md:grid-cols-6 lg:grid-cols-8 items-center mb-16 gap-x-12 gap-y-8 w-full mt-12 '>
        {/*<NumberCircle><h2 className='mb-0 '>1</h2></NumberCircle>*/}
        <div className='md:col-span-6 lg:col-span-4 xl:col-span-5 '>
          <h2 className="mb-4">Sign up to stay informed</h2>
          <p>We'll periodically send important information about California housing development for developers,
          property owners, architects and more.</p>
        </div>
        <div className="hidden md:block lg:hidden"></div>
        <div className="md:col-span-5 lg:col-span-4 xl:col-span-3">
          <EmailForm/>
        </div>
      </div>

    </section>

  )
}
