import { StaticImage } from "gatsby-plugin-image";
import React from "react";

export function PHCALogo() {
  return (<StaticImage
      src="../images/PHCA_Logo_Text_Greyscale.jpg"
      alt="Passive House California Logo"
      placeholder="blurred"
      width={120}
      className=""
      transformOptions={{fit: "cover", cropFocus: "center"}}
    />
  )
}
